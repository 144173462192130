import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import Typography from '@mui/material/Typography';

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const JuniorSchool= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
              <Container  maxWidth={0.78}  paddingTop={'0 !important'}>
          <Grid>
          <Typography variant={'h4'} sx={{ fontWeight: 700 }}
              align={'center'}>
               Primary School <br/>
              </Typography>
              <Typography component='p' align={'justify'} color="text.secondary">
             <br/>In the primary curriculum, the school provides a learning environment where the natural curiosity and wonder of young children are enhanced by skillful guidance from our professional team. At the Primary School level, all our young learners are given well-designed opportunities and experiences that will help develop and fine tune their literacy, numeracy, inter-personal skills and creativity. The students develop collaborative skills through participation in group activities, thereby laying a strong foundation in their academic life.
             <br/><br/>We also believe that a play way method is a fundamental component of early learning for the all-round development of children in their formative years. In practical terms this means longer periods of active engagement and shorter period of appropriate teaching, with a strong emphasis on reading and counting. The programme also empowers young minds to build their personalities through self-discipline and self-confidence. To keep the students abreast with the new technology around the world, we have introduced Robotics for the students of classes 1-5.

              </Typography>
          </Grid>

        </Container>
      <Container maxWidth={0.78}  paddingTop={'0 !important'}>
      <Form />
      </Container>
    </Main>
  );
};

export default JuniorSchool;
    